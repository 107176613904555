import React from "react"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import "../components/layout.css"
import styled from "styled-components"
import { motion } from "framer-motion"
// import { Link } from "gatsby"

const Main = styled(motion.div)`
  display: flex;
  justify-content: center;
  min-height: 100vh;

  @media (max-width: 414px) {
    & {
      display: block;
      margin-top: 60px;
    }
  }
`

const hero = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
}

const IndexPage = () => (
  <Main
    initial="hidden"
    animate="visible"
    variants={hero}
    transition={{ type: "spring", ease: "easeIn", delay: 0.2, duration: 3 }}
  >
    <SEO title="Rocco Marinaccio" />
    <Hero video={require("../video/my_animoji.gif")} fullname="Rocco Marinaccio" />
  </Main>
)

export default IndexPage
