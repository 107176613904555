import React from "react"
import styled from "styled-components"
import Themes from "../components/Foundations"

const SocialButton = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${Themes.light.athensGrey};
  color: #0d0c22;
  border-radius: 8px;
  padding: 10px 16px;
  margin-right: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  text-decoration: none;
  font-size: 16px;
  transition: all 0.2s ease;

  &:hover {
    background: ${Themes.light.ironGrey};
  }

  @media (prefers-color-scheme: dark) {
    &:hover {
      background: ${Themes.dark.gunMetalBlack};
    }
  }

  &:active {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
  }

  svg {
    margin-right: 8px;
  }

  @media (prefers-color-scheme: dark) {
    & {
      background: ${Themes.dark.tuexdoBlack};
      color: ${Themes.dark.pureWhite};
    }
  }

  @media (max-width: 576px) {
    & {
      font-size: 14px;
      padding: 12px 0;
      width: 100%;
      margin-bottom: 12px;
    }
  }
`

const Button = props => (
  <SocialButton target="_blank" href={props.url}>
    {props.icon}
    {props.label}
  </SocialButton>
)

export default Button
