import React from "react"
import styled from "styled-components"
import Themes from "../components/Foundations"
import Button from "../components/Button"
import Icons from "./Icons"

const HeroGroup = styled.div`
  align-self: center;
  max-width: 800px;
  padding: 0 24px;
`

const Animoji = styled.img`
  height: 150px;
  width: auto;

  @media (max-width: 414px) {
    & {
      height: 130px;
    }
  }
`

const SourceFile = styled.source``

const FullName = styled.h1`
  font-size: 32px;
  color: #0d0c22;
  font-weight: 700;
  margin: 24px 0 8px;

  @media (prefers-color-scheme: dark) {
    & {
      color: ${Themes.dark.pureWhite};
    }
  }

  @media (max-width: 414px) {
    & {
      font-size: 24px;
    }
  }
`

const Bio = styled.p`
  color: ${Themes.light.gunPowderGrey};
  margin: 0 0 32px;
  font-size: 20px;

  @media (prefers-color-scheme: dark) {
    & {
      color: ${Themes.dark.boulderGrey};
    }
  }
  @media (max-width: 414px) {
    & {
      font-size: 17px;
    }
  }
`

const Hero = props => (
  <HeroGroup>
    <Animoji src={props.video} />
    <FullName>{props.fullname}</FullName>
    <Bio>
      I’m a Principal Product Designer at{" "}
      <a target="_blank" rel="noopener noreferrer" href="https://thescore.com/">
        theScore
      </a>
      , based in Toronto. Previously at{" "}
      <a target="_blank" rel="noopener noreferrer" href="http://500px.com/">
        500px
      </a>{" "}
      and{" "}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://medium.com/cibc-live-labs"
      >
        Live Labs{" "}
      </a>
      before that. I solve problems through design to drive business strategy,
      humanize technology and impact people's lives. I also enjoy mentoring aspiring designers on ADPList and building plugins to automate the tedious parts of design in Figma.
    </Bio>

    <Button
      label={"LinkedIn"}
      icon={Icons()[2]["svg"]}
      url={"https://www.linkedin.com/in/rocco-marinaccio"}
    ></Button>
    <Button
      label={"ADPList"}
      icon={Icons()[3]["svg"]}
      url={"https://adplist.org/mentors/rocco-marinaccio"}
    ></Button>
    <Button
      label={"Dribbble"}
      icon={Icons()[0]["svg"]}
      url={"https://dribbble.com/rmarinac89"}
    ></Button>
    <Button
      label={"Twitter"}
      icon={Icons()[1]["svg"]}
      url={"https://twitter.com/rmarinac89"}
    ></Button>
  </HeroGroup>
)

export default Hero
